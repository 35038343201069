import * as React from 'react';
import { useId } from 'react';

import { cn } from '~/utils/cn';

import { ErrorList, ListOfErrors } from './error-list';
import { Label } from './label';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'text-md flex h-10 w-full rounded-md border bg-background px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-lg',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
  key,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  key?: string;
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const hasErrors = !!errors?.length;
  const errorId = hasErrors ? `${id}-error` : undefined;
  const { className: inputClassName, ...otherInputProps } = inputProps;
  return (
    <div className={cn('space-y-2', className)}>
      <Label htmlFor={id} className="text-md md:text-lg" {...labelProps} />
      <Input
        key={key}
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        className={cn(
          {
            'border-input': !hasErrors,
            'border-destructive': hasErrors,
          },
          inputClassName
        )}
        {...otherInputProps}
      />
      <div className="text-destructive">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}
